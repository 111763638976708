import {ReactElement, useEffect} from 'react';
import {Grid, Box, Container, Paper as MUIPaper, Stack, Typography, styled, CircularProgress} from "@mui/material";
import {useParams} from "react-router-dom";
import {InvoiceActions} from "./actions/invoice";
import {useAppDispatch} from "../App/hooks/store";

const Background = styled(Box)(({theme}) => ({
  backgroundColor: 'rgb(243, 244, 246)'
}));

const Code = styled(Typography)(({theme}) => ({
  fontWeight: 600,
  fontSize: '20px',
  fontFeatureSettings: 'normal',
  fontVariationSettings: 'normal',
  lineHeight: '28px',
  color: 'rgb(17, 24, 39)'
}));

const Message = styled(Typography)(({theme}) => ({
  marginTop: '8px',
  fontSize: '14px',
  fontFeatureSettings: 'normal',
  fontVariationSettings: 'normal',
  lineHeight: '22.75px',
  color: 'rgb(156, 163, 175)'
}));

const Paper = styled(MUIPaper)(({theme}) => ({
  backgroundColor: 'rgb(255, 255, 255)',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  overflow: 'hidden',
  boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(107, 114, 128, 0.2) 0px 25px 50px -12px',
  borderRadius: '0.5rem',
  padding: '16px 48px',
}));

export function Formation(): ReactElement | null {
  const dispatch = useAppDispatch()
  const { token } = useParams()

  const loading = (token: string) => {
    setTimeout(() => {
      dispatch(InvoiceActions.link(token)).then((response) => {
        const { link } = response

        if (link) {
          window.location.href = link
        } else {
          loading(token)
        }
      })
    }, 10000)
  }

  useEffect(() => {
    if (token) {
      loading(token)
    }
  }, [token]);

  return (
    <Background>
      <Container>
        <Stack height="100vh" direction="column" alignItems="center" justifyContent="center">
          <Grid container direction="row" alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={10} md={9}>
              <Paper>
                <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                  <Grid item xs={12} sm={10}>
                    <Code>Формируем ссылку на оплату</Code>
                    <Message>Дождитесь завершения операции</Message>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Grid container direction="row" alignItems="center" justifyContent="center">
                      <Grid item>
                        <CircularProgress />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Background>
  )
}